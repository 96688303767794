import moment from "moment"
import i18n from "@/i18n"

// // eslint-disable-next-line import/prefer-default-export
export function dateToLocalString(date: string) {
  moment.locale(i18n.locale.toUpperCase())
  return moment(date)
    .add(543, "year")
    .format("DD MMMM YYYY")
}
